import { sanity } from '~/sanity/sanity-client';
import {
  SHED_ALL_DATA_QUERY,
  SHED_ARTICLE_QUERY,
  SHED_ARTICLES_BY_SLUG_QUERY,
  SHED_CATEGORY_PAGE_QUERY,
  SHED_HOME_PAGE_QUERY,
} from '~/sanity/shed/shed-queries';

export const fetchShedArticle = async (slug) => {
  const res = await sanity({
    query: SHED_ARTICLE_QUERY,
    variables: {
      slug,
    },
  });

  return res;
};

export const fetchShedArticlesBySlugs = async (slugs) => {
  const res = await sanity({
    query: SHED_ARTICLES_BY_SLUG_QUERY,
    variables: {
      slugs,
    },
  });

  return res;
};

export const fetchShedData = async () => {
  const res = await sanity({
    query: SHED_ALL_DATA_QUERY,
  });

  return res;
};

export const fetchShedHomePageData = async () => {
  const res = await sanity({
    query: SHED_HOME_PAGE_QUERY,
  });

  return res;
};

export const fetchShedCategoryData = async (slug) => {
  const res = await sanity({
    query: SHED_CATEGORY_PAGE_QUERY,
    variables: {
      slug,
    },
  });

  return res;
};
