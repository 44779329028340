import { useQuery } from 'react-query';

import {
  fetchShedArticle,
  fetchShedArticlesBySlugs,
  fetchShedCategoryData,
  fetchShedData,
  fetchShedHomePageData,
} from '~/sanity/shed/shed-api';

export const useArticle = (slug) => {
  const {
    data: article,
    error,
    isLoading,
  } = useQuery(['shedArticle', slug], () => fetchShedArticle(slug));

  return {
    article,
    error,
    isLoading,
  };
};

export const useArticlesBySlugs = (slugs = []) => {
  const {
    data: articles,
    error,
    isLoading,
  } = useQuery(
    ['shedArticle', ...slugs],
    () => fetchShedArticlesBySlugs(slugs),
    {
      enabled: slugs && slugs?.length > 0,
    }
  );

  return {
    articles,
    error,
    isLoading,
  };
};

export const useShedData = (options = {}) => {
  const { data, error, isLoading } = useQuery(
    'allShedData',
    () => fetchShedData(),
    options
  );

  return {
    data,
    error,
    isLoading,
  };
};

export const useHomePageData = () => {
  const { data, error, isLoading } = useQuery('shedHomePage', () =>
    fetchShedHomePageData()
  );

  return {
    data,
    error,
    isLoading,
  };
};

export const useCategoryData = (slug) => {
  const { data, error, isLoading } = useQuery(['shedCategory', slug], () =>
    fetchShedCategoryData(slug)
  );

  return {
    data,
    error,
    isLoading,
  };
};
